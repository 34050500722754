const variables = {
  textColor: '#fff',
  facebookColor: '#4267B2',
  instagramColor: '#C13584',
  youtubeColor: '#FF0000',
  whatsappColor: '#4AC959',
  blogColor: '#FF585D',
  linkedinColor: '#2867B2',
  twitterColor: '#1DA1F2',
  blueskyColor: '#1DA1F2',
  githubColor: '#24292e',
  twitchColor: '#9146FF',
  docColor: '#007f54',
  tiktokColor: '#ff0050',
  opt1Color: '#366251',
  opt2Color: '#FF8300',
}

export default variables;
