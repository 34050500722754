import React from 'react';
import { Container } from './styles';
import {
  faYoutube,
  faInstagram,
  faTwitch,
  faGoogleDrive,
  faTiktok,
  faBluesky,
} from "@fortawesome/free-brands-svg-icons";
import Button from '../Button';
import variables from '../../variables';
import Header from '../Header';

export default function LinkTree() {
  return (
    <Container>
      <Header picture='https://jaidstore.blob.core.windows.net/images/20201010_113310_sm.png' title='Jaidlyn' subtitle='Commentator, host, content creator, and cosplayer' />
      <Button link='https://www.twitch.tv/jaidlyngaming' icon={faTwitch} name='Twitch' backgroundColor={variables.twitchColor} />
      <Button link='https://bsky.app/profile/jaidlyngaming.bsky.social' icon={faBluesky} name='Bluesky' backgroundColor={variables.blueskyColor} />
      <Button link='https://www.youtube.com/channel/UCqKZJBBb4x6kFzPd8TLowwg' icon={faYoutube} name='YouTube' backgroundColor={variables.youtubeColor} />
      <Button link='https://www.instagram.com/jaidlyngaming/' icon={faInstagram} name='Instagram' backgroundColor={variables.instagramColor} />
      <Button link='https://www.tiktok.com/@jaidlyngaming' icon={faTiktok} name='TikTok' backgroundColor={variables.tiktokColor} />
      <Button link='https://docs.google.com/document/d/1LKyheJ9wlGw1q_SRryVj2kEdrLG9uoCKyauE4NHLySk/edit?usp=sharing' icon={faGoogleDrive} name='Commentary Resume' backgroundColor={variables.docColor} />
    </Container>
  )
}
